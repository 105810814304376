import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  NotificationsService,
  PageContextService,
  PrgCoreModule,
  PrgEntityTypesModule,
  PrgFilterMenuModule,
  PrgSharedComponentsModule,
  UtilityService,
} from '@prg/prg-core-lib';
import { TreeDragDropService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { OrderListModule } from 'primeng/orderlist';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitterModule } from 'primeng/splitter';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { UserAvailabilitiesModule } from '../user-availabilities/user-availabilities.module';
import { WorkOrderModule } from '../work-order-manager/modules/work-order/work-order.module';
import { WorkOrderOperationsComponent } from './components/work-order-operations/work-order-operations.component';
import { WorkOrderRecurringComponent } from './components/work-order-recurring/work-order-recurring.component';
import { WorkOrderModalComponent } from './components/work-orders-calendar/components/work-order-modal/work-order-modal.component';
import { WorkOrdersCalendarPage } from './components/work-orders-calendar/work-orders-calendar.page';

@NgModule({
  declarations: [
    WorkOrdersCalendarPage,
    WorkOrderOperationsComponent,
    WorkOrderRecurringComponent,
    WorkOrderModalComponent,
  ],
  imports: [
    BadgeModule,
    CommonModule,
    TranslateModule,
    SplitterModule,
    TreeModule,
    CardModule,
    FormsModule,
    FullCalendarModule,
    DialogModule,
    ButtonModule,
    ToastModule,
    TooltipModule,
    ToolbarModule,
    DividerModule,
    OrderListModule,
    DragDropModule,
    PrgCoreModule,
    PrgEntityTypesModule,
    RippleModule,
    ToggleButtonModule,
    CheckboxModule,
    SelectButtonModule,
    InputNumberModule,
    DropdownModule,
    FormsModule,
    WorkOrderModule,
    TagModule,
    BadgeModule,
    CalendarModule,
    ToggleButtonModule,
    UserAvailabilitiesModule,
    PrgSharedComponentsModule,
    PrgFilterMenuModule,
  ],
  providers: [
    TreeDragDropService,
    //ConfirmationService,
    UtilityService,
    NotificationsService,
    PageContextService,
  ],
  exports: [
    WorkOrderModalComponent,
    WorkOrdersCalendarPage,
    WorkOrderOperationsComponent,
    WorkOrderRecurringComponent,
  ],
})
export class WorkOrdersCommonModule {}
